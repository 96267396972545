import React from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import styled from "styled-components"
import Heading from "@components/elements/heading"
import Container from "@components/layout/container"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import List from "@components/elements/list"
import { Form } from "@components/common/form"
import HubspotForm from "react-hubspot-form"
import { graphql } from "gatsby"
import Image from "@components/image"
import { processImages } from "@utils/process-images"

const DemoList = styled(List)`
  li::before {
    content: "";
    display: inline-block;
    min-height: 16px;
    min-width: 16px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiByeD0iMTAiIGZpbGw9IiM2MDcwRkMiLz4KPHBhdGggZD0iTTcuOTk5OTggMTIuNzhMNS4yMTk5OCAxMEw0LjI3MzMyIDEwLjk0TDcuOTk5OTggMTQuNjY2N0wxNiA2LjY2NjY4TDE1LjA2IDUuNzI2NjhMNy45OTk5OCAxMi43OFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 12px;
  }
  li {
    font-weight: 600;
  }
`

const RequestDemoPage = ({ data }) => {
  const images = processImages(data.images.edges)

  return (
    <Layout
      title="Request a Demo"
      description={`Request a demo of Klipfolio. See how you can build dashboards for your team and clients.`}
      fullWidth
      background="#edf3fd"
      hideOverflow
    >
      <Container text>
        <Grid
          height="100%"
          margin="6rem 0 8rem"
          marginSm="2rem 0 4rem"
          gap="4rem"
          alignItems="center"
          columns="1fr 1fr"
          columnsMd="1fr"
        >
          <Flex gap="2rem">
            <Image
              height={60}
              width={266}
              file={images["b6e018a3-2db1-43a6-a8fe-0d2c89e5c7fd"]}
            />
            <Heading as="h1" fontSize="2.1rem">
              The first and only analytics tool you need.
            </Heading>
            <DemoList
              gap="1rem"
              fontSize="1rem"
              color="#4B57C5"
              listStyle="none"
              margin="0"
            >
              <li>Bring all of your business data together in one place</li>
              <li>Choose from 100s of pre-built metrics</li>
              <li>
                Store your data history to compare time periods and explore
                historical trends
              </li>
              <li>Free forever, no credit card required</li>
            </DemoList>
            <Image file={images["01b338db-466e-4532-801c-ad31eaf71340"]} />
          </Flex>
          <Flex
            boxShadow="0 15px 20px -10px rgb(32 51 70 / 30%), 0 0px 30px 0px rgb(32 51 70 / 20%)"
            background="white"
            padding="2rem 2rem 0"
            gap="2rem"
            borderRadius="2rem"
          >
            <Heading as="h4">See Klipfolio in action</Heading>
            <Form>
              <HubspotForm
                portalId="7477725"
                formId="a0788cf8-8d01-41f2-9b12-4eee8cf54a5e"
              />
            </Form>
          </Flex>
        </Grid>
      </Container>
    </Layout>
  )
}

RequestDemoPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default RequestDemoPage

export const pageQuery = graphql`
  query RequestDemoQuery {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "b6e018a3-2db1-43a6-a8fe-0d2c89e5c7fd"
            "01b338db-466e-4532-801c-ad31eaf71340"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`
